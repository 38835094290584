import { deepmerge } from "@mui/utils";
import { allColors } from "styles/themes/common/colors";
import { layout } from "styles/themes/common/layout";
import { commonMuiTheme } from "styles/themes/common/mui";
import { BaseTheme } from "styles/themes/baseTheme";
import { typography } from "styles/themes/common/typography";
import { Theme } from "@mui/material/styles";
import { switchClasses } from "@mui/material";

const theme = {
	project: {
		borders: {
			presets: {
				primary: `1px solid ${allColors.hex.grayscale.white50}`,
				outline: "2px solid rgba(0, 0, 0, 0.12)"
			},
			radius: {
				small: 4,
				large: 10,
				extraLarge: 16
			}
		},
		colors: {
			allColors,
			backgroundColors: {
				main: allColors.hex.grayscale.white50,
				secondary: allColors.hex.grayscale.gray200,
				tertiary: allColors.hex.grayscale.gray100,
				contrast: allColors.hex.grayscale.white50,
				disabled: allColors.hex.grayscale.gray300,
				placeholder: allColors.hex.grayscale.white50,
				tooltip: allColors.hex.grayscale.white50,
				active: allColors.hex.blue.light.lightBlue50,
				error: "#FDEDED"
			},
			fontColors: {
				main: allColors.hex.grayscale.gray750,
				secondary: "rgba(0, 0, 0, 0.6)",
				contrast: allColors.hex.grayscale.white50,
				tertiary: allColors.hex.grayscale.gray700,
				disabled: allColors.hex.grayscale.white50,
				label: allColors.hex.grayscale.white50
			},
			primaryColors: {
				main: allColors.hex.blue.cwg.cwgBlue900
			},
			secondaryColors: {
				primary: allColors.hex.grayscale.white50
			},
			semanticColors: {
				error: "rgb(211, 47, 47)",
				success: allColors.hex.green.green650,
				warning: allColors.hex.orange.orange500,
				"warning:hover": allColors.hex.orange.orange500
			}
		},
		layout,
		typography
	}
} as const satisfies BaseTheme;

export const combinedTheme: Partial<Theme> = deepmerge(commonMuiTheme, {
	components: {
		MuiAlert: {
			variants: [
				{
					props: { severity: "info" },
					style: {
						color: theme.project.colors.primaryColors.main,
						svg: {
							fill: theme.project.colors.primaryColors.main
						}
					}
				}
			]
		},
		MuiAutocomplete: {
			styleOverrides: {
				inputRoot: {
					backgroundColor: theme.project.colors.backgroundColors.main
				}
			}
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: theme.project.colors.primaryColors.main,
					fontSize: "1rem",
					fontWeight: 400
				}
			},
			variants: [
				{
					props: { variant: "remove" },
					style: {
						color: theme.project.colors.fontColors.main,
						textDecoration: `underline ${theme.project.colors.fontColors.main}66`
					}
				}
			]
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					"&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
						borderColor: theme.project.colors.primaryColors.main
					}
				}
			}
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					"&.Mui-error": {
						color: theme.project.colors.semanticColors.error
					},
					"&.Mui-focused:not(.Mui-error)": {
						color: theme.project.colors.primaryColors.main
					}
				}
			}
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					"&.Mui-checked": {
						color: theme.project.colors.primaryColors.main
					}
				}
			}
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					color: theme.project.colors.primaryColors.main,
					"&.Mui-checked": {
						color: theme.project.colors.primaryColors.main
					}
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderColor: `rgba(${allColors.rgb.blue.cwg.cwgBlue900}, 0.5)`
				}
			}
		},
		MuiSwitch: {
			styleOverrides: {
				switchBase: {
					[`&.${switchClasses.checked}`]: {
						color: theme.project.colors.primaryColors.main
					}
				},
				track: {
					backgroundColor: theme.project.colors.primaryColors.main
				}
			}
		}
	}
} satisfies Partial<Theme>);

export type DefaultTheme = typeof theme;

export default theme;
