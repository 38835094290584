import styled from "@emotion/styled";

interface Props {
	isAddOnLandingPage?: boolean;
}

export const PlaceOrderOriginalOrderDetailsWrapper = styled.div<Props>(({ theme, isAddOnLandingPage }) => ({
	width: "calc(100% - 32px)",
	backgroundColor: isAddOnLandingPage ? theme.palette.grey[100] : theme.project.colors.backgroundColors.main,
	display: "flex",
	flexDirection: "column",
	padding: 16,
	span: {
		...theme.project.typography.overline,
		color: theme.project.colors.fontColors.main
	}
}));

export const PlaceOrderOriginalOrderDetailsDetails = styled.div<Props>(({ theme, isAddOnLandingPage }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(8, minmax(0, 1fr))",
	columnGap: isAddOnLandingPage ? 24 : 16,
	div: {
		display: "flex",
		flexDirection: "column",
		margin: "4px 0",
		"span:first-of-type": {
			...theme.project.typography.subtitle.subtitle2,
			color: theme.project.colors.fontColors.main
		},
		"span:last-of-type": {
			...theme.project.typography.body.body2,
			color: theme.project.colors.fontColors.secondary
		},
		span: {
			textTransform: "none"
		},
		overflowWrap: "break-word"
	},
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "repeat(1, 1fr)",
		rowGap: "8px",
		marginRight: 0,
		div: {
			flexDirection: "row",
			alignItems: "center",
			gap: "8px",
			margin: 0
		}
	}
}));
