import { ORDER_PARENT_BRANDS } from "../constants/orderParentBrands";
import { UNIQUE_BRAND_NAMES } from "constants/uniqueBrandNames";
import { convertToTitleCase } from "./string";
import { NoDesignerText, UnavailableDataPlaceholderText } from "../constants/text.ts";
import { HeadCell, Order } from "../components/NewOrders/SaveDraft/SaveDraftTable/components/SortableTableHead.tsx";
import {
	SaveDraftTHeadAccount,
	SaveDraftTHeadDate,
	SaveDraftTHeadDesigner,
	SaveDraftTHeadDraft,
	SaveDraftTHeadProductLine
} from "../components/NewOrders/constants.ts";

export const filterSpecificBrands = (parentBrand: string | undefined | null) => {
	const uniqueBrand = UNIQUE_BRAND_NAMES.find((i) => i.input === parentBrand);
	if (uniqueBrand) {
		return uniqueBrand.output;
	} else {
		return convertToTitleCase(parentBrand);
	}
};

export const brandCheck = (parentBrand: any) => {
	return !!(parentBrand !== "UNKNOWN" && ORDER_PARENT_BRANDS.find((brand: any) => brand === parentBrand));
};

export const displayDraftDesigner = (
	designerName: string | undefined | null,
	designerNumber: string | undefined | null,
	isFilter?: boolean
) => {
	if (
		(designerName && designerName !== UnavailableDataPlaceholderText) ||
		(designerNumber && designerNumber !== UnavailableDataPlaceholderText)
	) {
		return [designerName, designerNumber].filter(Boolean).join(" - ");
	} else {
		return isFilter ? NoDesignerText : UnavailableDataPlaceholderText;
	}
};

export const displayDesigner = (designer: any) => {
	if (typeof designer === "string") {
		return designer;
	}

	if (designer?.firstName && designer?.lastName) {
		return `${designer.firstName} ${designer.lastName} - ${designer.designerNumber}`;
	}

	if (designer?.firstName) {
		return `${designer.firstName} - ${designer.designerNumber}`;
	}

	if (designer?.lastName) {
		return `${designer.lastName} - ${designer.designerNumber}`;
	}

	return designer?.designerNumber;
};

export const sortBy = (initOrders: any, sortBy: string = "order-date") => {
	const orders = [...initOrders];

	if (orders.length > 1) {
		const alpha = orders.sort((a: any, b: any) => {
			// Since alphabet is second tier, orders gets sorted alphabetically first
			const jobNameA = a.jobName || "";
			const jobNameB = b.jobName || "";
			if (jobNameA < jobNameB) return -1;
			if (jobNameA > jobNameB) return 1;
			const orderNumberA = parseInt(a.orderNumber);
			const orderNumberB = parseInt(b.orderNumber);
			return orderNumberA - orderNumberB;
		});
		if (sortBy === "order-date") {
			return alpha.sort((a: any, b: any) => {
				const dateA = new Date(a.orderDate).getTime();
				const dateB = new Date(b.orderDate).getTime();
				return dateB - dateA;
			});
		}
	}
	return orders;
};

export const headCells = [
	{
		id: "updatedAt",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadDate,
		sortable: true
	},
	{
		id: "draft",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadDraft,
		sortable: false
	},
	{
		id: "account",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadAccount,
		sortable: false
	},
	{
		id: "designer",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadDesigner,
		sortable: false
	},
	{
		id: "productLine",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadProductLine,
		sortable: false
	},
	{
		id: "empty",
		numeric: false,
		disablePadding: false,
		label: "",
		sortable: false
	}
] as const satisfies HeadCell[];

export type CellIds = (typeof headCells)[number]["id"];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

export function getComparator(order: Order, orderBy: CellIds): (a: any, b: any) => number {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}
