import { useCallback, useEffect, useRef, useState } from "react";

interface UseTimerConfig {
	autoStart?: boolean;
	onDone?: () => void;
	onReset?: () => void;
	onStart?: () => void;
	timeout: number;
}

function useTimer({ autoStart = false, onDone, onReset, onStart, timeout }: UseTimerConfig) {
	const timeoutHandle = useRef<ReturnType<typeof setTimeout>>();
	const [isDone, setIsDone] = useState(false);
	const [isRunning, setIsRunning] = useState(false);

	const reset = useCallback(() => {
		clearTimeout(timeoutHandle.current);
		timeoutHandle.current = undefined;
		onReset?.();
		setIsDone(false);
	}, [onReset]);

	const start = useCallback(() => {
		if (!isRunning && !timeoutHandle.current) {
			onStart?.();
			setIsDone(false);
			setIsRunning(true);

			timeoutHandle.current = setTimeout(() => {
				timeoutHandle.current = undefined;
				onDone?.();
				setIsDone(true);
				setIsRunning(false);
			}, timeout);
		}
	}, [isRunning, onDone, onStart, timeout]);

	useEffect(() => {
		if (autoStart) {
			start();
		}
	}, [autoStart, start, timeout]);

	useEffect(() => () => clearTimeout(timeoutHandle.current), []);

	return {
		isDone,
		isRunning,
		reset,
		start
	};
}

export default useTimer;
