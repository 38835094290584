import styled from "@emotion/styled";

export const ShippingDetailsHeaderWrapper = styled.div(({ theme }) => ({
	...theme.project.typography.overline,
	color: theme.project.colors.fontColors.main,
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	"div:nth-of-type(1)": {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end"
	}
}));

export const OrderInformationWrapper = styled.div(({ theme }) => ({
	color: theme.project.colors.fontColors.main,
	display: "flex",
	justifyContent: "space-between",
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column"
	},
	"div:nth-of-type(2)": {
		...theme.project.typography.overline
	}
}));
