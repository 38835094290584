import styles from "../../pages/replacements-page-styles.module.css";
import { Grid } from "@mui/material";
import { DoorStyleText, FinishText } from "../../constants/text";
import { ReplacementCartItem } from "features/reducers/replacementOrder/cart.ts";
import { ReplacementHeaderText, SkuDescriptionContainer } from "./ReplacementHeaderText.styles";

interface ReplacementReasonsHeaderContainerProps {
	item: ReplacementCartItem;
}
const ReplacementReasonsHeaderContainer = ({ item }: ReplacementReasonsHeaderContainerProps) => {
	return (
		<Grid
			container
			className={styles.selectReplacementReasonContainerHeader}
		>
			<Grid
				xs={6}
				item
				data-testid="replacementReasonHeader-sku-description"
			>
				<div className={styles.lineItemNumberSkuDescriptionContainer}>
					<span className="body1">{item.lineItemNumber}</span>
					<SkuDescriptionContainer>
						<ReplacementHeaderText data-testid="replacementReasonHeader-sku">
							{item.sku}
						</ReplacementHeaderText>
						<span
							data-testid="replacementReasonHeader-description"
							className="body1"
						>
							{item.description}
						</span>
					</SkuDescriptionContainer>
				</div>
			</Grid>
			<Grid
				container
				alignItems="center"
				sm={6}
				xs={12}
			>
				<Grid
					item
					xs={12}
					sm={6}
				>
					<div className={styles.selectReplacementReasonDoorStyleAndFinish}>
						<span className="subtitle2">{DoorStyleText}</span>
						<span
							className="body2"
							data-testid="replacementReasonHeader-doorStyle"
						>
							{item.doorStyle}
						</span>
					</div>
				</Grid>
				<Grid
					item
					xs={6}
				>
					<div className={styles.selectReplacementReasonDoorStyleAndFinish}>
						<span className="subtitle2">{FinishText}</span>
						<span
							className="body2"
							data-testid="replacementReasonHeader-finish"
						>
							{item.finish}
						</span>
					</div>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ReplacementReasonsHeaderContainer;
